import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CustomNavbar extends Component {
    render() {
        return (
            <nav id="fixed-top" className="navbar navbar-toggleable-sm transparent-nav navbar-expand-lg">
                <div className="container"><Link to="/"><span className="navbar-brand"><img src={require('../images/logo.png')} alt="logo" /><img src={require('../images/logo-dk.png')} alt="logo" /></span></Link><button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                <div>
                    <h6> <i>&nbsp; &nbsp; &nbsp; &nbsp; {<FontAwesomeIcon icon={['fas', 'phone-volume']}/>}</i>&nbsp; &nbsp; +27 83 632 8273</h6>
                    <h6> <i>&nbsp; &nbsp; &nbsp; &nbsp; {<FontAwesomeIcon icon={['fas', 'envelope']}/>}</i>&nbsp; &nbsp; selectivegas@vodamail.co.za</h6>
                </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item"><a className="nav-link dropdown-toggle active" href="#home">Home</a></li>
                            <li className="nav-item"><a className="nav-link" href="#ourwork">Our Work</a></li>
                            <li className="nav-item"><a className="nav-link" href="#faq">FAQ</a></li>
                            <li className="nav-item"><a className="nav-link" href="#pricing">Get a Quote</a></li>
                            <li className="nav-item"><a className="nav-link" href="#download">Find Us</a></li>
                        </ul>
                        {/* <a href="#download" className="get-btn">Contact Us</a> */}
                    </div>
                </div>
            </nav>
        );
    }
}

export default CustomNavbar;