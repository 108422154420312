import React, { Component } from 'react';
import Title from '../Title';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Faq extends Component {
    componentDidMount(){
        $(".faq_accordian_two .card").click(function(){
            $(".faq_accordian_two .card").removeClass("active");
            $(this).addClass('active');
        });
    }
    render() {
        return (
            <section className="faq_solution_area features_area_pad" id="faq">
                <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    <li data-parallax='{"x": -100, "y": 100}'><img className="br_shape" src={require ('../../images/line/fq_line-1.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -200, "y": 200}'><img className="br_shape" src={require('../../images/line/fq_line-2.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img className="br_shape" src={require('../../images/line/fq_line-3.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img className="br_shape" src={require('../../images/line/fq_line-4.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img src={require('../../images/line/fq_line-5.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": -100, "y": 100}'><img src={require('../../images/line/fq_line-6.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img src={require('../../images/line/fq_line-7.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 250, "y": -250}'><img src={require('../../images/line/fq_line-8.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": -150}'><img src={require('../../images/line/fq_line-9.png')} alt="f_img"/></li>
                    <li data-parallax='{"y": -180}'><img src={require('../../images/line/fq_line-10.png')} alt="f_img"/></li>
                </ul>
                <div className="container">
                    <Title Stitle="Any questions?" pfont="Maybe we can quickly help you. Here are some frequently asked questions."/>
                    <div className="row ">
                        <div className="col-md-6 d_flex">
                            <div id="accordion" className="faq_accordian_two">
                                <div className="card wow fadeInUp active">
                                    <div className="card-header" id="heading1">
        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse0" aria-expanded="true" aria-controls="collapse0"><i >{<FontAwesomeIcon icon={['fas', 'certificate']}/>}</i>Why do I need a COC ? </button></h5>
                                    </div>
                                    <div id="collapse0" className="collapse show" aria-labelledby="heading1" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Just like any other specialised field of expertise, both knowledge and experience are required to induce reliability and safety into a project. Hence, all installations must be done by a registered installer with the South African Qualification and Certification Committee for Gas (SAQCC). Appliances must also conform to the South African National Standards (SANS 10087-1) and be properly marked with the Liquefied Petroleum Gas Safety Association of Southern Africa (LPGSASA) label.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading2">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1"><i >{<FontAwesomeIcon icon={['fas', 'fire']}/>}</i>Is my flame burning properly ? </button></h5>
                                    </div>
                                    <div id="collapse1" className="collapse " aria-labelledby="heading2" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>When the burner is set incorrectly, the products of combustion will be high in carbon dioxide (CO2), water vapour (H20) and nitrogen (N). This can create harmful carbon monoxide (CO) gases which must be avoided and typically produces an orange coloured flame. The flame must be a healthy blue colour.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading3">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2"><i >{<FontAwesomeIcon icon={['fas', 'hammer']}/>}</i>How can I do self-maintenance ? </button></h5>
                                    </div>
                                    <div id="collapse2" className="collapse " aria-labelledby="heading3" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Visibly inspect the installation regularly for any visible deteriation. Mainly the rubber hosing connecting the cylinder and the regulator. If you detect any crack or smell the gas, contact Selective Gas Installations immediately.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading4">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3"><i >{<FontAwesomeIcon icon={['fas', 'sort']}/>}</i>Does LPG rise or settle? </button></h5>
                                    </div>
                                    <div id="collapse3" className="collapse " aria-labelledby="heading4" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Liquid Petroleum Gas vapour is heavier than air. If you have a leak, the vapour will accumulate on the floor and forms a flammable mixture. It is non-toxic, but since it can induce headaches and dizziness when inhaled and should undeniably be avoided.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card wow fadeInUp ">
                                    <div className="card-header" id="heading5">
                                        <h5 className="mb-0"><button className="btn btn-link" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4"><i >{<FontAwesomeIcon icon={['fas', 'egg']}/>}</i>Can you smell LPG ? </button></h5>
                                    </div>
                                    <div id="collapse4" className="collapse " aria-labelledby="heading5" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Liquid Petroleum Gas vapour is odourless. Consequently, LPG is mixed with Ethanethiol or commonly known as Ethyl Mercapton. This organosulfur compound is colourless but odorous (smells like rotten eggs). As soon as you detect the smell, turn off the main shut-off valve and isolate the cylinder. Contact Selective Gas Installations immediately.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="faq_image_mockup wow fadeInUp" data-wow-delay="200ms"><img src={require('../../images/mobile_mockup_3.png')} alt="featured"/></div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Faq;