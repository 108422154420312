import React, { Component } from 'react';
import Title from './Title';
import PriceItem from './QuoteItem';

class Price extends Component {
    constructor(){
        super();
        this.state = {
            posts:[
                {
                    "id": 100,
                    "ptitle": "1. Get in touch",
                    "info": "As easy as gas",
                    "rate": "FREE Quote",
                    'list1': 'No obligation free quote ',
                    'list2': 'We can schedule a visit',
                    'list3': 'Free advice',
                    'list4': 'A friendly smile',
                },
                {
                    "id": 101,
                    "ptitle": "2. We'll get back to you",
                    "info": "Detailed quote",
                    "rate": "Client specific",
                    'list1': 'Gas pipe work & fittings',
                    'list2': 'Gas cage(s)',
                    'list3': 'Drawing of plans',
                    'list4': 'Certificate of Compliance',
                },
                {
                    "id": 102,
                    "ptitle": "3. Quote Accepted",
                    "info": "You are happy, we can go ahead",
                    "rate": "Installation",
                    'list1': 'Schedule installation date',
                    'list2': 'Relax, we are experts',
                    'list3': 'Testing & inspection',
                    'list4': 'Issue COC for installation',
                }
            ]
        };
    }
    render() {
        return (
            <section className="priceing_area_two" id="pricing">
                <div className="container">
                    <Title Stitle="How to get a Quote ?" pfont="No obligation free quote in the Gauteng area"/>
                    <div className="row">
                        {this.state.posts.map(post =>(
                          <PriceItem key={post.id} post ={post} image={`price-icon${post.id}.png`}/>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default Price;