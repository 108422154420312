import React, { useState } from 'react'
import { Axios, db } from '../firebase/firebaseConfig'
import './styled.scss'
import {alertService} from '../services/alert.service';
import {Alert} from '.././component/Alert';

const ContactForm = () => {
  
  const [formData, setFormData] = useState({})
  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = event => {
    event.preventDefault()

    sendEmail()
    setFormData({
      name: '',
      email: '',
      contactnumber:'',
      suburb:'',
      message: '',
    })
  }
  //'https://us-central1-your-app-name.cloudfunctions.net/submit'
  //'https://us-central1-selective-gas-installations.cloudfunctions.net/submit'
  const sendEmail = () => {
    Axios.post(
      'https://us-central1-selective-gas-installations.cloudfunctions.net/submit',
      formData
    )
      .then(res => {
        db.collection('emails').add({
          name: formData.name,
          email: formData.email,
          contactnumber: formData.contactnumber,
          suburb: formData.suburb,
          message: formData.message,
          time: new Date(),
        })
        
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    
    <>
      <form className="wow fadeInUp" data-wow-delay="300ms" onSubmit={handleSubmit}>
      <h3 className="wow fadeInUp" data-wow-delay="300ms">Get a quick estimate</h3>
        <input
          type="text"
          name="name"
          placeholder="Name"
          onChange={updateInput}
          value={formData.name || ''}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          onChange={updateInput}
          value={formData.email || ''}
        />
        <input
          type="contactnumber"
          name="contactnumber"
          placeholder="Contact number"
          onChange={updateInput}
          value={formData.contactnumber || ''}
        />
        <input
          type="suburb"
          name="suburb"
          placeholder="Suburb"
          onChange={updateInput}
          value={formData.suburb || ''}
        />
        <textarea
          type="text"
          name="message"
          placeholder="Requirements"
          onChange={updateInput}
          value={formData.message || ''}
        ></textarea>
        <Alert />
        <button type="submit" onClick={() => alertService.info("Successfully submitted! We will get back to you ASAP.",  {autoClose: true, keepAfterRouteChange: true} )}>Submit</button>
        {/* <button type="submit">Submit</button>  */}
      </form>
    </>
  )
}

export default ContactForm