import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Action extends Component {
    render() {
        let imgUrl = require('../images/get_bg.jpg')
        const divStyle = {
            background: 'linear-gradient(0deg, rgba(42, 42, 42,1), rgba(18, 51, 117,0.5)), url(' + imgUrl + ') no-repeat',
        }
        return (
            <section className="new_call_action_area" style={divStyle} id="download">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                            <div className="action_mobile wow fadeInUp"></div>
                        </div>
                        <div className="col-lg-8 d_flex col-md-7 col-sm-12 col-xs-12">
                            <div className="n_call_action_content">
                                <h2 className="wow fadeInUp"> Don't wait for ESKOM, cook your food NOW! </h2>
                                <div className="wow fadeInUp" data-wow-delay="300ms">
                                <h4> <i>{<FontAwesomeIcon icon={['fas', 'phone-volume']}/>}</i>&nbsp; +27 83 632 8273</h4>
                                <h4> <i>{<FontAwesomeIcon icon={['fas', 'envelope']}/>}</i>&nbsp; selectivegas@vodamail.co.za</h4>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Action;