import React, { Component } from 'react';
import ContactForm from '../../contact';
// import { Link } from 'react-router-dom';
class BannerContent extends Component {
    render(){
        var { topic} = this.props;
        return(
            <React.Fragment>
                <div className="n_banner_content">
                    <ContactForm />
                    <h2 className="wow fadeInUp" data-wow-delay="300ms">" Your peace of mind is our priority "</h2>
                    <p className="wow fadeInUp" data-wow-delay="500ms">{topic}</p>
                    {/* <p className="wow fadeInUp" data-wow-delay="500ms">{subtopic}</p>
                    <p className="wow fadeInUp" data-wow-delay="500ms">{coctopic}</p> */}
                    <a className="nav-link" href="#ourwork"><span className="n_banner_btn wow fadeInUp" data-wow-delay="600ms">See How We Do it</span></a>
                </div>
            </React.Fragment>
            
        );
    }
}

export default BannerContent;