import React, { Component } from 'react';

class About extends Component {
    constructor(){
        super()
        this.state = {
            title:  'Our Story',
            text:  'Selective Gas Installations was born in the heart of Limpopo province back in 2009. We first started with domestic installations and were involved with larger projects in new developments like Legend Golf & Safari Resort and Die Oog retirement development.',
            text2: 'Due to the large demand in commercial installations, our business extended into this sector. Hence, Selective Gas Installations acquired a commercial license to satisfy the demand. Evidently, we have successfully completed over 1000 installations in various provinces accross the country.',
            text3:  'We are currently located in Krugersdorp and are fully operational in the Gauteng area. ',
        }
    }
    render(){
        return(
            <section className="about_area" id="about_area">
                <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    <li data-parallax='{"x": -100, "y": 100}'><img className="br_shape" src={require('../images/line/line_01.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": -200, "y": 200}'><img className="br_shape" src={require('../images/line/line_02.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img className="br_shape" src={require('../images/line/line_03.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img className="br_shape" src={require('../images/line/line_04.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": -150, "y": 150}'><img src={require('../images/line/line_05.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": -100, "y": 100}'><img src={require('../images/line/line_06.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": 50, "y": -50}'><img src={require('../images/line/shape.png')} alt="f_img" /></li>
                    <li data-parallax='{"x": 250, "y": -250}'><img src={require('../images/line/shape-1.png')} alt="f_img" /></li>
                </ul><img className="shape wow fadeInRight" src={require('../images/shape3.png')} alt="f_img" />
                <div className="container">
                    <div className="row row_reverse align_items_center">
                        <div className="col-lg-7">
                            <div className="about_img text-right"><img src={require('../images/app-img.png')} alt="f_img" /></div>
                        </div>
                        <div className="col-lg-5">
                            <div className="about_content">
                                <h2 className="h_color f_36 wow fadeInUp">{this.state.title}</h2>
                                <div className="br"></div>
                                <div className="wow fadeInUp" data-wow-delay="300ms">
                                    <p>{this.state.text}</p>
                                    <p>{this.state.text2}</p>
                                    <p>{this.state.text3}</p>
                                </div>
                                <a className="nav-link" href="#home"><span className="n_banner_btn wow fadeInUp" data-wow-delay="450ms"> Contact Us </span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default  About;